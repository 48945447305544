.form {
    &.wishlist {
        &.items {
            .actions-toolbar {
                @extend .abs-reset-left-margin;
            }
        }
    }
}

.wishlist-index-index {
    .actions-toolbar {
        .primary {
            width: 100%;
            text-align: left;

            button {
                &.action {
                    max-width: 325px;

                    &.share {
                        @include lib-button-primary();
                    }

                    &.tocart {
                        @include lib-button-checkout();
                    }
                }
            }
        }
    }

    .sidebar-additional {
        margin-top: 0;
    }

    .action {
        &.delete,
        &.edit {
            top: 5px;
        }

        &.delete {
            right: 25px;
        }

        &.edit {
            left: 0;
            position: absolute;

            &:before {
                @include lib-icon-svg($icon-base-edit, $color-gray-light3);
                width: 16px;
                height: 16px;
                background: none;
                display: inline-block;
            }

            &>span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }
    }

}

.sidebar {
    .block-wishlist {
        .product-item {
            margin-bottom: 10px;

            .product-item-info {
                padding: 5px 0 15px;
                margin: 0;
                border: 0;

                .product-item-actions {
                    margin-top: 10px;

                    .actions-primary {
                        width: 70%;
                        display: inline-block;
                    }

                    .actions-secondary {
                        width: 25%;
                        display: inline-block;

                        .action {
                            &.delete {
                                @include lib-button-primary();
                                position: unset;
                                width: 100%;
                                padding: 12px;
                                text-align: center;

                                &:before {
                                    @include lib-icon-svg($icon-base-close, $color-white);
                                    width: 9px;
                                    height: 20px;
                                    margin-top: -10px;
                                }
                            }
                        }
                    }
                }

                .product-item {
                    &-photo {
                        position: relative;
                    }

                    &-details {
                        margin-left: 0;
                        @include lib-vendor-prefix-display();
                        @include lib-vendor-prefix-flex-direction();
                    }
                }

                .price-as-configured {
                    @include lib-vendor-prefix-display();
                }
            }

            &:hover {
                .product-item {
                    &-info {
                        box-shadow: none;
                        border: 0;
                    }

                    &-actions {
                        display: inline-block;
                        position: unset;
                        width: 100%;
                        left: 0;
                        z-index: 100;
                        margin-top: $indent__s;
                        margin-left: 0;
                        border: 0;
                        border-top: 0;
                        box-shadow: none;
                    }
                }
            }

            .primary {
                border-width: 2px;
                border-style: solid;
                padding: 12px;
            }
        }

        .product-items {
            .product-item-info {
                .product-item-photo	{
                    float: none;
                }
            }
        }
    }
}

.product-items,
.products.list.items {
    .action {
        &.towishlist {
            float: right;
            margin-top: -15px;
            margin-right: -15px;

            span {
                padding-left: 8px;
            }

            &:before {
                @include lib-icon-svg($icon-base-wishlist, $color-gray-light9);
                display: inline-block;
                width: 18px;
                height: 16px;
                background: transparent;
            }

            &.active,
            &:hover {
                &:before {
                    @include lib-icon-svg($icon-base-wishlist-hover, $theme__primary__color);
                }
            }
        }
    }
}

.product-info-main {
    .product-addto-links {
        .action {
            &.towishlist {
                padding-left: 0;
                display: flex;
                align-items: center;
                text-decoration: none;

                span {
                    padding-left: 8px;
                }

                &:before {
                    @include lib-icon-svg($icon-base-wishlist, $color-gray-light9);
                    display: inline-block;
                    width: 18px;
                    height: 16px;
                    background: transparent;
                }

                &:hover,
                &.active {
                    &:before {
                        @include lib-icon-svg($icon-base-wishlist-hover, $theme__primary__color);
                    }
                }
            }
        }
    }
}

.abs-actions-addto-gridlist {
    position: absolute;
    right: 20px;
    top: 11px;
    z-index: 99;
    width: 21px;
    height: 21px;

    &:before {
        @include lib-icon-svg($icon-base-wishlist, $color-gray-light9);
        display: inline-block;
        width: 18px;
        height: 16px;
        background: transparent;
    }

    &:hover {
        &:before {
            @include lib-icon-svg($icon-base-wishlist-hover, $theme__primary__color);
        }
    }
}

.account {
    .table-wrapper {
        .data {
            &.table {
                &.wishlist {
                    @include lib-table-bordered(
                        $_table_type: horizontal
                    );

                    thead {
                        > tr {
                            > th {
                                border-bottom: 0;
                            }
                        }
                    }

                    tbody {
                        > tr {
                            &:last-child {
                                > td {
                                    border-bottom: 1px solid $table__border-color;
                                }
                            }
                        }
                    }

                    .product {
                        &.name {
                            display: inline-block;
                            margin-bottom: $indent__s;
                        }
                    }

                    .box-tocart {
                        margin: $indent__s 0;

                        .qty {
                            @extend .abs-input-qty;
                            vertical-align: middle;
                        }
                    }

                    .col {
                        &.item {
                            width: 50%;
                        }

                        &.photo {
                            max-width: 150px;
                        }

                        &.selector {
                            max-width: 15px;
                        }
                    }

                    textarea {
                        margin: $indent__s 0;
                    }

                    .input-text {
                        &.qty {
                            margin-bottom: $indent__s;
                        }
                    }

                    .action {
                        &.primary {
                            vertical-align: top;
                        }
                    }

                    .price {
                        @include lib-css(font-weight, $font-weight__bold);
                    }
                }
            }
        }
    }
}

.block-wishlist {
    .block-title {
        @include lib-font-size($font-size__base);
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }

    .product-items {
        &.no-display {
            display: none;
        }
    }
}

.products-grid.wishlist {
    .product {
        &-item {
            &-photo {
                display: block;
                margin-bottom: $indent__s;
            }

            &-name {
                margin-top: 0;
            }

            .price-box {
                margin: 0;
            }

            .comment-box {
                .label {
                    @extend .abs-visually-hidden;
                }
            }

            &-comment {
                display: block;
                height: 42px;
                margin: $indent__s 0;
            }

            &-actions {
                > * {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .box-tocart {
                input {
                    &.qty {
                        @extend .abs-input-qty;
                        height: 32px;
                    }
                }
            }
        }
    }
}

.wishlist-mobile-header {
    @include lib-vendor-prefix-order(3);
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-box-align(center);
    justify-content: center;
    height: 100%;
    max-height: 65px;

    span {
        &.wishlist-icon {
            &:before {
                @include lib-icon-svg($icon-base-wishlist, $theme__primary__color);
                display: inline-block;
                width: 18px;
                height: 16px;
                background: transparent;
            }
        }
    }

    .counter {
        @include lib-css(background, $color-blue6);
        @include lib-css(color, $color-white);
        @include lib-font-size($font-size__s);
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        width: 20px;
        color: $color-white;
        position: absolute;
        right: 30px;
        top: 13px;
        padding: 1px;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.form-wishlist-items {
    .product-item .box-tocart .tocart span {
        display: block;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-grid {
        &.wishlist {
            .product-item {
                border-bottom: 1px solid $secondary__color;
                &:first-child {
                    border-top: 1px solid $secondary__color;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .wishlist-mobile-header {
        padding: 0;

        .counter {
            right: -7px;
        }

        span {
            &.wishlist-icon {
                &:before {
                    @include lib-icon-svg($icon-base-wishlist, $color-white);
                }
            }
        }
    }

    .products-grid {
        &.wishlist {
            margin-bottom: $indent__l;
            margin-right: -$indent__s;

            .product {
                &-item {
                    padding: $indent__base $indent__s;
                    position: relative;

                    &-photo {
                        float: left;
                        margin-right: $indent__base;
                    }

                    &-name {
                        @include lib-font-size(16);
                    }

                    &-actions {
                        display: block;

                        .action {
                            margin-right: 15px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &.edit {
                                float: left;
                            }

                            &.delete {
                                float: right;
                            }

                            &.edit,
                            &.delete {
                                margin-top: 7px;
                            }
                        }
                    }

                    &-name,
                    &-description,
                    .price-box,
                    &-tooltip {
                        margin-left: 95px;
                    }

                    .box-tocart {
                        margin: 15px 0;
                        float: left;

                        .stock {
                            margin-top: 7px;
                        }
                    }

                    .giftregisty-dropdown {
                        display: none;
                    }
                }

                &-image-container {
                    max-width: 80px;
                }
            }
        }
    }

    //
    //  Grid view for wishlist
    //  -----------------------------------------

    .wishlist-index-index {
        .product {
            &-item {
                width: 100%;

                &-info {
                    width: auto;
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .wishlist-index-index {
        .products-grid {
            .product-item {
                margin-bottom: $indent__base;
                position: relative;
            }

            .product-item-actions {
                margin: 0;
                position: unset;
                display: block;
                z-index: 1;
                border: none;
                border-top: 0;
                box-shadow: none;
                margin-top: -2px;
                padding: 0;
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .products-grid {
        &.wishlist {
            .product {
                &-item {
                    &-tooltip {
                        display: inline-block;
                    }

                    &-actions {
                        margin: $indent__s 0 0;
                    }

                    .fieldset {
                        .field {
                            &.qty {
                                margin-bottom: $indent__s;
                                padding-right: $indent__s;

                                .label {
                                    width: auto;
                                }
                            }
                        }
                    }

                    .box-tocart {
                        .actions-primary {
                            margin: 0;
                        }

                        .stock {
                            margin: $indent__base 0 0;
                        }
                    }
                }
            }
        }
    }

    .wishlist-index-index {
        .product {
            &-item {
                &-info {
                    width: 240px;
                }
            }
        }

        .main {
            .form-wishlist-items {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .wishlist-index-index {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 24.439%) / 3);
                padding: 0;
                width: 24.439%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
}
