//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color !default;
$account-nav-color: false !default;

$account-nav-current-border: 3px solid transparent !default;
$account-nav-current-border-color: $theme__primary__color !default;
$account-nav-current-color: false !default;
$account-nav-current-font-weight: $font-weight__semibold !default;

$account-nav-delimiter__border-color: $color-gray82 !default;

$account-nav-item-hover: $color-gray91 !default;

$_password-default: $color-gray-light01 !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;

.customer-account-login {
    .page-title-wrapper {
        display: none;
    }
}

.customer-account-create,
.account {
    .page-title-wrapper {
        margin-top: 40px;
    }

    #tooltip-label {
        display: none;
    }
}

.customer-account-logoutsuccess {
    .page-title-wrapper {
        margin-top: 80px;
    }
}

.header-account {
    @include lib-vendor-prefix-order(3);
    @include lib-vendor-prefix-display(flex);
    justify-content: center;
    max-height: 65px;
    height: 100%;
}

.account-link-label {
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-box-align(center);
    justify-content: center;

    &:after {
        @include lib-icon-svg($icon-base-user, $theme__primary__color);
        background: transparent;
        height: 22px;
        width: 19px;
        margin: 0;
        display: inline-block;
    }

    span {
        display: none;
    }
}

.login-container {
    .block {
        &-customer-login {
            width: 100%;

            .block-title {
                border: none;

                strong {
                    @include lib-font-size($font-size__xxl);
                    font-weight: $font-weight__bold;
                }
            }

            .form-login {
                .note {
                    display: none;
                }

                .email {
                    .input-text {
                        height: 45px;
                        margin-top: 4px;
                    }
                }

                .password {
                    margin-top: 15px;

                    .input-text {
                        height: 45px;
                        margin-top: 4px;
                    }
                }

                .field.captcha {
                    margin-top: 15px;

                    .input-text {
                        height: 45px;
                        margin-top: 4px;
                    }
                }

                .actions-toolbar {
                    @include lib-vendor-prefix-display();
                    @include lib-vendor-prefix-flex-wrap();
                    margin-top: 17px;
                    margin-left: 0;

                    .remember {
                        float: left;
                        width: 50%;

                        input[name=remember-me] {
                            margin: 4px 6px 0 0;
                        }
                    }

                    .login {
                        @include lib-css(font-weight, $font-weight__regular);
                        @include lib-css(font-family, $font-family__montserrat);
                        float: left;
                        width: 50%;
                        margin: 0;
                        text-align: right;

                        &:after {
                            font-style: $font-style__emphasis;
                            color: $color-black;
                            margin: 0 0 2px;
                        }
                    }

                    .primary {
                        float: left;
                        text-align: center;
                        margin-top: 15px;

                        .primary {
                            @include lib-button-primary();
                            width: auto;
                        }
                    }

                    .secondary {
                        float: left;
                        width: 50%;
                        margin-top: 56px;

                        a {
                            text-align: right;
                            margin: 0;

                            span {
                                @include lib-font-size($font-size__m);
                                color: $color-blue4;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        &-new-customer {
            width: 100%;

            .block {
                &-title {
                    border: none;

                    strong {
                        @include lib-font-size($font-size__xxl);
                        font-weight: $font-weight__bold;
                    }
                }

                &-content {
                    p {
                        @include lib-font-size($font-size__m);
                    }
                }
            }

            .actions-toolbar {
                margin-top: 25px;

                .primary {
                    a {
                        @include lib-button-primary();
                    }
                }
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size($font-size__l);
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);

        &:after {
            margin-top: 35px;
        }
    }
}

.block-addresses-list {
    .items {
        .addresses {
            > .item {
                margin-bottom: $indent__base;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.form-address-edit {
    input[name=region_id] {
        display: none;
    }

    .actions-toolbar {
        .action {
            &.primary {
                @extend .abs-button-l;
            }
        }
    }
}

.form-edit-account {
    .fieldset {
        .password {
            display: none;
        }
    }
}

.box {
    &-billing-address,
    &-shipping-address,
    &-information,
    &-newsletter {
        .box-content {
            line-height: 26px;
        }
    }

    &-information {
        .change-password {
            display: none;
        }
    }
}

//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account
//  ---------------------------------------------

.account {
    .column {
        &.main {
            h2 {
                margin-top: 0;
            }

            .toolbar {
                text-align: center;

                .limiter-options {
                    width: auto;
                }
            }

            .limiter {
                > .label {
                    @extend .abs-visually-hidden;
                }
            }

            .block {
                &:not(.widget) {
                    @extend .abs-account-blocks;
                }
            }
        }
    }

    .sidebar-additional {
        margin-top: 40px;
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }

    .order-details-items {
        .table-order-items {
            .mark,
            mark {
                background-color: transparent;
            }

            .product-item-name {
                min-height: unset;
            }

            .totals.tax.details {
                .mark {
                    width: 70%;
                }
            }
        }
    }
}

//  Checkout address (create shipping address)
.field {
    &.street {
        .field {
            &.additional {
                .label {
                    @extend .abs-visually-hidden;
                }
            }
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        @include lib-css(background, $account-nav-background);
        padding: 15px 0;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-left: 3px solid transparent;
            display: block;
            padding: $indent__xs 18px $indent__xs 15px;
        }

        a {
            text-decoration: none;

            &:hover {
                @include lib-css(border-color, $account-nav-current-border-color);
                @include lib-css(color, $account-nav-current-color);
            }
        }

        &.current {
            a,
            strong {
                @include lib-css(border-color, $account-nav-current-border-color);
                @include lib-css(color, $account-nav-current-color);
            }

            a {
                @include lib-css(border-color, $account-nav-current-border-color);
            }
        }

        .delimiter {
            border-top: 1px solid $account-nav-delimiter__border-color;
            display: block;
            margin: $indent__s 1.8rem;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;

    .column {
        &.main & {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .title {
        margin-bottom: $indent__s;

        strong {
            @include lib-heading(h4);

            .column {
                &.main & {
                    @include lib-font-size($h3__font-size);
                }
            }
        }
    }

    p {
        &:last-child {
            margin: 0;
        }
    }

    .box-actions {
        margin-top: $indent__xs;
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field {
    &.password {
        .control {
            @include lib-vendor-prefix-display();
            @include lib-vendor-prefix-flex-direction();

            .mage-error {
                @include lib-vendor-prefix-order(2);
            }

            .input-text {
                @include lib-vendor-prefix-order(0);
                z-index: 2;
            }
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: $form-element-input__height;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control {
    &.captcha-image {
        @include lib-css(margin-top, $indent__s);

        .captcha-img {
            vertical-align: middle;
        }
    }
}

.lac-notification {
    &-sticky {
        padding: 10px 0;
        background-color: $color-white-smoke;

        &.fixed {
            box-shadow: 0 0 10px 0 $color-gray-light3;
            top: calc(50% - 40px) !important;
            left: 0;
            z-index: 9;
            max-width: 200px;
            position: fixed;
        }
    }

    &-icon {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column {
            &.main,
            .sidebar-additional {
                margin: 0;
            }
        }
    }
}

@include max-screen($screen__m) {
    .account-link {
        height: 62px;
        position: unset;

        &:hover {
            background-color: transparent;
            border-bottom: none;

            .account-link-label {
                &:after {
                    @include lib-icon-svg($icon-base-user, $color-white);
                }
            }
        }

        &-label {
            padding: 23px 10px 20px;

            &:after {
                @include lib-icon-svg($icon-base-user, $color-white);
            }

            &:hover {
                &:after {
                    @include lib-icon-svg($icon-base-user, $color-white);
                }
            }
        }
    }

    .account-dropdown {
        top: 65px;
    }

    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {
        .page-title-wrapper {
            margin-top: 0;
        }

        .page {
            &.messages {
                margin-bottom: $indent__xl;
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }

        .order-links {
            margin-bottom: 0;
        }

        .order {
            &-details-items {
                .table-order-items {
                    .totals.tax.details {
                        .mark {
                            text-align: left;
                            width: 70%;
                        }
                    }

                    td {
                        &.col {
                            &.sku,
                            &.qty,
                            &.price {
                                padding: 1px 0 !important;
                            }

                            &.subtotal {
                                margin-top: 10px;
                            }
                        }
                    }
                }

                .order-title {
                    display: none;
                }
            }

            &-actions-toolbar {
                a {
                    margin: 5px 0;
                    display: block;
                }
            }
        }
    }

    .control {
        .captcha-image {
            .captcha-img {
                @include lib-css(margin-bottom, $indent__s);
                display: block;
            }
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }

    .account-link-label {
        &.account-link-direct {
            @include lib-vendor-prefix-display(flex);
            padding: 0;
            height: 62px;
            align-items: center;
        }
    }
}

@include max-screen($screen__l) {
    .account-link {
        @include lib-font-size($font-size__s);

        &:hover {
            position: initial;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;

            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            &:after {
                @extend .abs-margin-for-forms-desktop;
            }

            > .field {
                > .control {
                    width: 55%;
                }
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    @include lib-css(width, $form-field-type-control-inline__width);
                    float: left;
                }
            }
        }
    }

    .form {
        &.password,
        &.send,
        &.create {
            &.reset,
            &.confirmation,
            &.forget,
            &.account {
                min-width: 600px;
                width: 50%;
            }
        }
    }

    //
    //  My account
    //  ---------------------------------------------

    .account {
        &.page-layout-2columns-left {
            .sidebar-main,
            .sidebar-additional {
                width: 22.3%;
            }

            .column {
                &.main {
                    width: 77.7%;

                    .block {
                        &:not(.widget) {
                            .block-content {
                                @extend .abs-add-clearfix-desktop;

                                .box {
                                    @extend .abs-blocks-2columns;
                                }
                            }
                        }
                    }
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items {
            &.addresses {
                @extend .abs-add-clearfix-desktop;
                @include lib-font-size(0);

                > .item {
                    @include lib-font-size($font-size__base);
                    display: inline-block;
                    margin-bottom: $indent__base;
                    vertical-align: top;
                    width: 48.8%;

                    &:nth-last-child(1),
                    &:nth-last-child(2) {
                        margin-bottom: 0;
                    }

                    &:nth-child(even) {
                        margin-left: 2.4%;
                    }
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control {
        &.captcha-image {
            .captcha-img {
                margin: 0 $indent__s $indent__s 0;
            }
        }
    }
}
