//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : false !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : $color-white !default;


body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.account,
.customer-account-create,
.cms-index-index,
.customer-account-forgotpassword,
.cms-noroute-index,
.checkout-cart-index,
.checkout-onepage-success {
    #maincontent {
        &.page-main {
            margin-top: 125px;
        }
    }
}

.shopreview-form-index,
.shopreview-review-listing,
.wishlist-index-index,
.customer-account-login,
.customer-account-createpassword {
    #maincontent {
        &.page-main {
            margin-top: 145px;
        }
    }
}

.checkout-index-index {
    #maincontent {
        &.page-main {
            margin-top: 205px;
        }
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title {
            & + .action {
                margin-top: $indent__l;
            }
        }
    }
}

.action {
    &.skip {
        &:not(:focus) {
            @extend .abs-visually-hidden;
        }

        &:focus {
            @include lib-css(background, $color-gray94);
            @include lib-css(padding, $indent__s);
            box-sizing: border-box;
            left: 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;
            z-index: 15;
        }
    }
    &-skip-wrapper {
        height: 0;
        position: relative;
    }
}

.page-layout-2columns-left {
    .sidebar-main {
        width: 25%;
        padding-right: 0;
    }

    .column.main {
        width: 73%;
        padding-left: 2%;
    }
}

header {
    background-color: $color-white;
    position: absolute;
    width: 100%;
    z-index: 1000;
    max-height: 100%;

    .logo {
        max-width: 100%;
        margin: 0;
        width: 100%;
        height: 100%;

        img {
            width: calc(100% - 15px);
            height: auto;
            margin-bottom: 5px;
            transition: width 0.5s ease-out;
        }

        &-mobile {
            display: none;
        }
    }

    .header {
        &.content {
            padding: 11px 0 0;
            height: 80px;
        }
    }

    .col {
        &-logo {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            height: 100%;
        }

        .category-button {
            @include lib-font-size($font-size__base);
            position: relative;
            font-family: $font-family__montserrat;
            top: 0;
            line-height: $line-height__xs;

            &-title {
                @include lib-font-size($font-size__m);
                font-weight: $font-weight__semibold;
                text-transform: capitalize;
            }

            &-down {
                display: inline-block;
                background-image: url(lib-icon-svg-url($icon-base-arrow-down, $color-white));
                background-size: 10px 10px;
                width: 10px;
                height: 10px;
                margin-left: 7px;
            }
        }
    }

    .header-top-bar-container {
        @include lib-font-size($font-size__s);
        line-height: $line-height__xs;
        padding: 13px 0;
        background-color: $color-white;

        .header-top-bar {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;
                    margin-right: 19px;
                    margin-bottom: 0;

                    &:before {
                        content: url(lib-icon-svg-url($icon-base-check, $color-black));
                        display: inline-block;
                        width: 11px;
                        margin-right: 8px;
                    }
                }
            }
        }

        .header-top-call-us {
            p {
                margin-bottom: 0;
            }

            a {
                color: inherit;
            }
        }
    }

    &.fixed {
        position: fixed;
        border-bottom: 1px solid $theme__primary__color;
        box-shadow: 0 0 10px 0 $color-gray-light3;

        .header-top-bar-container,
        .secondary-menu-bar-container {
            display: none;
        }

        .logo {
            img {
                width: calc(100% - 65px);
            }
        }
    }

    .secondary-menu-bar-container {
        min-height: 68px;
        position: relative;
        z-index: 1;

        p {
            display: none;
        }

        .secondary-menu-bar-col {
            background-color: $theme__primary__color;
            margin-top: 23px;
        }

        .submenu-overlay {
            position: absolute;
            transition: background-color 0.5s ease;

            &.active {
                top: 0;
                bottom: -1px;
                left: 0;
                right: 0;
                z-index: 99;
                background-color: $theme__transparant-color-dark-darker;
            }
        }
    }
}

.category-menu-wrapper {
    display: none;
    position: absolute;
    top: 60px;

    &.active {
        width: 100%;
        display: block;
    }
}

.breadcrumbs {
    margin-top: 125px;
    margin-bottom: 0;

    strong {
        font-weight: $font-weight__bold;
    }

    ul {
        li {
            @include lib-css(font-family, $font-family__montserrat);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__semibold);
            line-height: $line-height__xl;
            color: $color-gray20;

            a,
            a:visited {
                font-weight: normal;
                color: $color-gray20;
            }
        }
    }

    .item {
        &:not(:last-child) {
            &:after {
                transform: rotate(-90deg);
                content: '\e607';
                line-height: $line-height__s;
                margin-right: 0;
                width: 18px;
            }
        }

        &-minicart {
            padding-right: 20px;
            max-width: 104px;
            align-self: flex-end;
            float: right;
        }
    }
}

//
//  Global notice
//  ---------------------------------------------
.message {
    &.global {
        p {
            margin: 0;
        }

        &.noscript,
        &.cookie {
            @include lib-message($_message-type: global-note);
            margin: 0;
        }

        &.cookie {
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            z-index: 3;

            .actions {
                margin-top: $indent__s;
            }
        }

        &.demo {
            @include lib-message($_message-type: global-caution);
            margin-bottom: 0;
            text-align: center;
        }
    }

    &.success {
        @include lib-font-size($font-size__l);
        margin: 0 0 10px;
        padding: 12px 20px 12px 25px;
        display: block;
        background: $color-gray90;
        color: $color-dark-green1 ;
        padding-left: 45px;
        position: relative;

        >*:first-child {
            &:before {
                @include lib-icon-svg($icon-base-check-circle, $color-dark-green1);
                vertical-align: middle;
                display: inline-block;
                left: 10px;
                top: 22px;
                width: 24px;
                height: 27px;
                position: absolute;
            }
        }
    }
    }

.action {
    &.delete {
        right: 10px;
        position: absolute;

        &:before {
            @include lib-icon-svg($icon-base-close, $color-gray-light3);
            width: 16px;
            height: 16px;
            background: none;
            display: inline-block;
        }

        &>span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.footer {
    &.content {
        width: 100%;
        max-width: 100%;
        padding: 0;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }

        .usp-container {
            ul {
                list-style-type: none;
                padding-left: 0;
                margin-left: 0;

                li {
                    padding-left: 30px;
                    margin-bottom: 8px;
                }
            }
        }
    }

    &.newsletter {
        @include lib-css(background-color, $theme__primary__color);
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }

    .social-links {
        text-indent: -9999px;
        display: inline-block;
        width: 35px;
        height: 35px;
        margin-right: 8px;
        background-color: $theme__primary__color;

        &.twitter-icon {
            @include svg-content-background($icon-base-twitter, $theme__primary__color);
            background-color: $color-white;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-twitter, $color-blue6);
                background-color: $color-white;
            }
        }

        &.facebook-icon {
            @include svg-content-background($icon-base-facebook, $theme__primary__color);
            background-color: $color-white;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-facebook, $color-blue6);
                background-color: $color-white;
            }

        }

        &.instagram-icon {
            @include svg-content-background($icon-base-instagram, $theme__primary__color);
            background-color: $color-white;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-instagram, $color-blue6);
                background-color: $color-white;
            }
        }

        &.linkedin-icon {
            @include svg-content-background($icon-base-linkedin, $theme__primary__color);
            background-color: $color-white;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-linkedin, $color-blue6);
                background-color: $color-white;
            }
        }

        &.pinterest-icon {
            @include svg-content-background($icon-base-pinterest, $theme__primary__color);
            background-color: $color-white;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-pinterest, $color-blue6);
                background-color: $color-white;
            }
        }

        &.youtube-icon {
            @include svg-content-background($icon-base-youtube, $theme__primary__color);
            background-color: $color-white;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-youtube, $color-blue6);
                background-color: $color-white;
            }
        }

        &.whatsapp-icon {
            @include svg-content-background($icon-base-whatsapp-square, $theme__primary__color);
            background-color: $color-white;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-whatsapp-square, $color-white);
                background-color: $theme__primary__color;
            }
        }
    }

    &-title {
        @include lib-font-size($font-size__xl);
        @include lib-css(font-weight, $font-weight__bold);
        padding-bottom: 15px;
    }

    .footer-contact {
        margin-bottom: 30px;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul {
                &.dropdown {
                    a {
                        display: block;
                        padding: 8px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        li {
            @include lib-font-size($font-size__s);
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}


//
//  Compare
//  ---------------------------------------------

.compare {
    &-wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 101;

        .block {
            &-compare {
                margin-bottom: 0;
                height: 0;
            }

            &-content {
                padding: 10px 30px 30px;
            }

            &-title {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-box-align(center);
                @include lib-vendor-prefix-flex-direction(row);
                flex-wrap: nowrap;
                background: $theme__primary__color;
                padding: 12px 0 12px 20px;
                border-bottom: 0;
                cursor: pointer;

                .counter.qty {
                    flex-grow: 0;
                    display: inline-block;
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    line-height: 2;
                    background-color: $page__background-color;
                    color: $primary__color;
                    @include lib-font-size($font-size__l);
                    @include lib-css(font-weight, $font-weight__bold);
                    margin-right: 10px;
                    text-align: center;
                }

                strong {
                    flex-grow: 2;
                    display: inline-block;
                    color: $color-white;
                    @include lib-css(font-weight, $font-weight__bold);
                    line-height: 1.2;
                    font-size: 14px;
                    text-transform: lowercase;

                    &:after {
                        display: inline-block;
                        content: "";
                        margin-left: 20px;
                        width: 15px;
                        height: 8px;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 148 84'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff%3B%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Evergelijker-in%3C/title%3E%3Cg id='Laag_2' data-name='Laag 2'%3E%3Cg id='Laag_1-2' data-name='Laag 1'%3E%3Cpath class='cls-1' d='M81.07%2C81.07l64-64A10%2C10%2C0%2C1%2C0%2C130.93%2C2.93L74%2C60l-57-57A10%2C10%2C0%2C1%2C0%2C2.93%2C17.07l64%2C64a10%2C10%2C0%2C0%2C0%2C14.14%2C0Zm0%2C0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat 0 0;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .product-item {
            position: relative;
            border-bottom: 1px solid $color-gray-middle-border;
            padding: 16px 16px 16px 0;
            margin: 0;

            .product-item-name {
                color: $primary__color;
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__bold);
                padding: 0;
                margin: 0;
                display: inline-block;
                min-height: inherit;
            }
        }

        a.action.compare {
            @include lib-button-primary();
        }

        .actions-toolbar {
            margin-top: 10px;

            .primary {
                max-width: 140px;
            }
        }
    }

    &-content {
        background: $color-gray-light0;
        position: absolute;
        width: 100%;
        padding: 0;
        bottom: 0;
        top: 0;
        right: 0;
        margin-bottom: -100%;
        box-shadow: 0 0 0 0 $color-gray-light1;
        border: 1px solid $color-gray-light4;
        transition: all 0.5s ease-out 0.05s;

        &.down {
            margin-top: -59px;
            transition: all 0.5s ease-out 0.05s;
        }

        &.up {
            margin-top: -332px;
            margin-bottom: 0;
            transition: all 0.5s ease-out 0.05s;
            box-shadow: 0 -5px 7px 0 $color-gray-light4;

            .block-title strong {
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }

    &-buffer {
        padding: 0 15px;
        width: 75%;
        max-width: 830px;
        position: relative;
        margin: 0 auto;
    }
}

.catalog-product_compare-index {
    .comparison {
        overflow: auto;
        width: 100%;
    }

    .table-comparison {
        width: 100%;
        table-layout: auto;

        &.product-item {
            &:hover {
                .product-item-info {
                    box-shadow: none;
                }
            }

            .product-item-info {
                border: none;
                width: 300px;
                max-width: 100%;
            }
        }

        tr {
            &.product-info {
                td {
                    position: relative;
                }
            }
        }

        .action.delete {
            &:before {
                @include lib-icon-svg($icon-base-close, $color-gray-light3);
                width: 16px;
                height: 16px;
                background: none;
                display: inline-block;
                font-size: unset;
            }
        }

        .product-item-photo {
            text-align: center;
        }

        .product-attr {
            &:nth-child(even) {
                background-color: $color-gray-light0;
            }

            td {
                border-left: 1px solid $color-gray-middle-border !important;

                &:first-child {
                    border: 0;
                }
            }
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget {
        &.block {
            &:not(:last-child) {
                @extend .abs-margin-for-blocks-and-widgets;
            }
        }
        &:not(:last-child) {
            @extend .abs-margin-for-blocks-and-widgets;
        }
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget {
        &.block {
            @include lib-css(margin, $indent__base 0);
        }
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation {
        ul {
            padding: 0 8px;
        }
    }

    .header {
        &.panel {
            > .header {
                &.links {
                    @include lib-list-inline();
                    @include lib-font-size(0);
                    float: right;
                    margin-left: auto;

                    > li {
                        @include lib-font-size($font-size__base);
                        margin: 0 0 0 15px;

                        &.welcome,
                        a {
                            line-height: 1.4;
                        }

                        &.welcome {
                            a {
                                @include lib-css(padding-left, $indent__xs);
                            }
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: $indent__l $indent__base 0;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel {
            &.wrapper {
                border-bottom: 1px solid $secondary__color;
            }
        }

        .header {
            &.panel {
                @extend .abs-add-clearfix-desktop;
                padding-bottom: $indent__s;
                padding-top: $indent__s;
            }
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;

                & + .action {
                    float: right;
                    margin-top: $indent__base;
                }
            }
        }
    }

    .logo {
        margin: -8px auto 25px 0;

        img {
            max-height: inherit;
        }
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher {
                &.store {
                    display: inline-block;
                    padding-right: 50px;
                    vertical-align: top;
                }
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}

@include screen($screen__m, $screen__l, $orientation: portrait) {
    header {
        position: fixed;
    }
}

@include max-screen($screen__m) {
    .page-header {
        border-bottom: none;
    }

    .menu-open {
        header {
            .header {
                &.content {
                    background-color: $color_white;
                    border-bottom: 2px solid $theme__primary__color;
                }
            }
        }

        .col {
            &-search {
                display: block;
            }
        }

        .logo-mobile,
        .search-mobile,
        .header-account,
        .col-minicart {
            display: none;
        }

        .col-logo {
            .logo {
                display: block;
            }
        }
    }

    header {
        position: fixed;

        .header {
            &.content {
                @include lib-vendor-prefix-display(flex);
                margin: 0;
                height: 62px;
                padding: 0;
                background-color: $theme__primary__color;

                .col {
                    &-logo {
                        @include lib-vendor-prefix-order(2);
                        @include lib-vendor-prefix-display(flex);
                        @include lib-vendor-box-align(center);
                        justify-content: center;
                        padding: 0;

                        .logo {
                            img {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }

        .logo {
            &-mobile {
                display: block;

                img {
                    width: auto;
                    height: 65px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .col-logo {
        .logo {
            display: none;
        }
    }

    .page-header {
        top: 0;
    }

    .account-link {
        &:after {
            display: none;
        }

        &-label {
            &:after {
                display: inline-block;
            }

            &:hover {
                &:after {
                    display: inline-block;
                }
            }

            span {
                display: none;
            }

            &.mobile-not-logged-in {
                display: none;
            }
        }

        &-direct {
            display: block;
        }
    }

    .col {
        &-menu {
            @include lib-vendor-prefix-order(1);
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);

            .search-mobile {
                margin-left: 15px;

                .trigger-search {
                    &:after {
                        @include lib-icon-svg($icon-base-search, $color-white);
                        display: block;
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

        &-minicart {
            @include lib-vendor-prefix-order(4);
        }

        &-search {
            position: absolute;
            top: 62px;
            width: 100%;
            background: $color-white;
            border-bottom: 1px solid $color-gray-middle-border;
            display: none;
        }
    }

    .header-account {
        padding: 0;
    }

    .shopreview-form-index,
    .shopreview-review-listing,
    .account,
    .catalog-product-view,
    .customer-account-create,
    .cms-index-index,
    .customer-account-forgotpassword,
    .cms-noroute-index,
    .checkout-cart-index,
    .checkout-onepage-success,
    .catalog-product_compare-index {
        #maincontent {
            &.page-main {
                margin-top: 70px;
            }
        }
    }

    .catalog-category-view {
        #maincontent {
            &.page-main {
                margin-top: 130px;
            }
        }
    }

    .checkout-index-index,
    .account {
        #maincontent {
            &.page-main {
                margin-top: 130px;
            }
        }
    }

    .compare-wrapper {
        display: none;
    }

    .grecaptcha-badge {
        visibility: hidden;
    }
}
