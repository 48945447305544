.ves-container {
    margin-bottom: 100px;

    &.row-50 {
        margin-bottom: 50px;
    }

    &.row-25 {
        margin-bottom: 25px;
    }

    &.row-0 {
        margin-bottom: 0;
    }

    &:first-of-type {
        margin-bottom: 15px;
    }

    .row-inner {
        &.clearfix {
            width: 100%;
        }
    }

    .featured-banner {
        .col-inner {
            height: 337px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            div {
                &:first-of-type {
                    @include lib-vendor-prefix-display(flex);
                    @include lib-vendor-box-align(center);
                    height: 100%;
                    justify-content: center;
                }
            }

            .ves-widget {
                &.block {
                    margin-bottom: 0;
                }
            }
        }
    }

    .block-content {
        h1 {
            margin: 20px 0 28px 0;
            font-family: $heading__font-family__base;
            font-weight: $h1__font-weight;
            font-size: $h1__font-size;
        }

        h2 {
            font-family: $h2__font-family;
            font-weight: $h2__font-weight;
            font-size: $h2__font-size;
            margin: 23px 0 3px 0;
        }

        ul {
            list-style-type: disc;
            margin-left: 12px;

            li {
                font-family: $font-family__montserrat;
                padding-left: 20px;
                margin-bottom: 0;
            }
        }

        table {
            &.table-style {
                margin: 40px 0;
                border: none;
                font-family: $font-family__montserrat;

                tr {
                    height: 50px;

                    &:first-child {
                        background: $theme__primary__color;
                        font-weight: $font-weight__heavier;
                        color: $color-white;
                        font-size: $font-size__m;
                    }

                    &:not(:first-child) {
                        td {
                            &:first-child {
                                font-weight: $font-weight__bold;
                            }
                        }
                    }

                    &:nth-child(odd) {
                        &:not(:first-child) {
                            background: $color-gray-light0;
                        }
                    }

                    td {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }
        }

        table {
            &.table-style-small {
                width: auto;

                td {
                    padding: 1px 5px;
                }
            }
        }
    }

    .ves-inner {
        .row-inner {
            @include lib-vendor-prefix-display(flex);
            flex-wrap: wrap;
        }

        .background-cover {
            min-height: 400px;

            .col-inner  {
                background-size: cover;
                background-position: center;
                height: 100%;
            }
        }

        .centered-content {
            @include lib-vendor-prefix-display(flex);

            .col-inner {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-box-align(center);
                justify-content: center;
                padding: 0 50px;

                .ves-widget {
                    &.block {
                        margin: 40px 0;
                    }
                }
            }

            h2 {
                @include lib-font-size($font-size__xxxl);
                font-family: $h2__font-family;
                font-weight: $h2__font-weight;
                margin: 23px 0;
            }

            p {
                line-height: $line-height__l;

                a {
                    &.button {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .ves-container {
        .ves-inner {
            .row-inner {
                display: block;
            }

            .background-cover {
                min-height: 250px;

                .col-inner  {
                    height: 250px;
                }
            }
        }
    }
}
