select {
  @include lib-font-size($font-size__s );
  @include lib-css(font-family, $font-family__montserrat);
  width: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.fieldset {
  .configurable {
    .label {
      @include lib-css(font-weight, $font-weight__regular);
      color: $color-gray20;
    }
  }
}