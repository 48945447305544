.cms-homepage {
    .columns {
        margin-top: 30px;
    }

    .usp-container {
        margin-top: 0;

        .usp-title {
            @include lib-css(font-family, $font-family__montserrat);
            @include lib-font-size($font-size__xxl);
            @include lib-css(font-weight, $font-weight__semibold);
            margin-bottom: 36px;
            line-height: $line-height__s;
            color: $theme__primary__color;
        }

        strong {
            @include lib-css(font-family, $font-family__montserrat);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__bold);
            display: block;
            padding-bottom: 8px;
            line-height: $line-height__xs;
        }

        span {
            @include lib-css(font-family, $font-family__montserrat);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__regular);
            display: inline-block;
            padding-bottom: 4px;
            line-height: $line-height__s;
        }

        .content {
            ul {
                @include lib-list-reset-styles();

                li {
                    @include lib-font-size($font-size__base);
                    height: auto;
                    padding-left: 30px;
                    margin-bottom: 8px;
                    line-height: $line-height__s;
                    font-family: $font-family__montserrat;
                    font-weight: $font-weight__regular;
                    background-image: url(lib-icon-svg-url($icon-base-usp, $color-orange2));
                    background-size: 18px 18px;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .ves-container {
        margin-bottom: 0;
    }
}

.icon-arrow-right {
    background: url(lib-icon-svg-url($icon-base-arrow-right, $color-white)) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 11px;

    &.primary {
        background: url(lib-icon-svg-url($icon-base-arrow-right, $color-orange2)) no-repeat;
    }
}

.homepage {
    &-category-row {
        .row-inner {
            flex-wrap: wrap;

            [class*="col-"] {
                flex-shrink: 0;
            }
        }
    }

    &-blok {
        margin-bottom: 30px;

        a {
            color: transparent;

            &:focus,
            &:hover {
                outline: none;
            }
        }

        .content {
            min-height: 357px;

            .title {
                display: none;
            }
        }

        .subtitle {
            @include lib-css(font-family, $font-family__montserrat);
            @include lib-font-size($font-size__xxl);
            @include lib-css(font-weight, $font-weight__semibold);
            line-height: $line-height__xs;
            color: $color-white;
            background: $color-gray20;
            padding: 27px 25px;
            border-top: 14px solid $color-gray64;
        }

        .small {
            .content {
                min-height: initial;
                width: 100%;
                padding: 0;
                line-height: 0;

                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
            }

            .subtitle {
                @include lib-font-size($font-size__l);
                line-height: $line-height__xs;
                padding: 25px 15px;
                border-top: 10px solid $color-gray64;
            }
        }

        &.secondary {
            .content {
                background: $color-gray-light0;
                padding: 20px 26px;
            }

            .subtitle:not(.kiyoh-container) {
                background: $theme__primary__color;
                border-color: $theme__primary__color__light;
            }

            .small {
                @include lib-css(font-family, $font-family__montserrat);
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__regular);
                line-height: $line-height__l;

                .content {
                    padding: 0;
                }

                a {
                    @include lib-css(font-weight, $font-weight__semibold);
                    line-height: $line-height__xs;
                    color: $color-gray20;
                }
            }
        }
    }

    &-banners {
        .banner-left {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            justify-content: center;
            height: 150px;
            background-color: $theme__primary__color;

            .highlight {
                @include lib-font-size($font-size__m);
                font-family: $font-family__montserrat;
                font-weight: $font-weight__bold;
                background-color: $theme__primary__color;
                text-transform: uppercase;
                padding: 8px 18px;
                top: 20px;
                left: 15px;
                position: absolute;
            }

            .text {
                &:after {
                    background: url(lib-icon-svg-url($icon-base-arrow-right, $color-white)) no-repeat;
                    content: " ";
                    height: 25px;
                    width: 25px;
                    display: inline-block;
                    top: 9px;
                    left: 5px;
                    position: relative;
                }
            }
        }

        .banner-right {
            height: 150px;

            .block-content {
                height: 100%;
            }

            .banner-overlay {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-prefix-flex-direction(column);
                justify-content: center;
                height: 100%;
                width: 100%;
                background-color: rgba($color-gray20, .9);
                padding-left: 32px;
                padding-right: 32px;

                div {
                    @include lib-css(font-family, $font-family__montserrat);
                    @include lib-font-size($font-size__l);
                    @include lib-css(font-weight, $font-weight__regular);

                    span {
                        color: $theme__primary__color;
                    }
                }
            }
        }

        a {
            color: $color-white;
            text-decoration: none;
        }
    }

    &-tips-container {
        margin-top: 80px;
        margin-bottom: 40px;

        .row-inner {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(stretch);

            .col-inner {
                height: 100%;
            }

            .ves-container {
                height: 100%;

                div {
                    height: 100%;
                }
            }

            .ves-inner {
                height: 100%;
            }
        }

        .homepage-tips {
            background: $color-gray20;
            margin-top: 0;
            padding-right: 0;

            .row-inner {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-box-align(stretch);

                .img-container {
                    position: relative;
                    max-width: 300px;
                    padding-right: 0;

                    .arrow {
                        width: 45px;
                        height: 43px;
                        z-index: 1;
                        position: absolute;
                        right: -26px;
                        top: 30px;
                        overflow: auto;
                        left: inherit;
                        margin-left: inherit;
                        bottom: inherit;
                    }

                    .col-inner {
                        border-right: 10px solid $color-gray64;
                        height: 100%;
                        background-size: cover;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .cms-homepage {
        .homepage-tips-container {
            overflow: hidden;

            > div {
                > .ves-inner {
                    > .row {
                        > .row-inner {
                            @include lib-vendor-prefix-flex-direction(column);
                        }
                    }
                }
            }

            .homepage-tip {
                padding-bottom: 20px;

                &:last-child {
                    padding-bottom: 0;
                }

                .row-inner {
                    @include lib-vendor-prefix-flex-wrap(wrap);
                    padding-right: 15px;

                    .img-container {
                        height: 30vw;
                        max-width: 100%;

                        .col-inner {
                            border: none;
                        }
                    }
                }

                .content {
                    margin-left: 15px;
                }
            }
        }

        .homepage-banners {
            .banner-left {
                .highlight {
                    @include lib-font-size($font-size__base);
                    line-height: $line-height__s;
                    top: 7px;
                }
            }
        }
    }

    .homepage {
        &-blok {
            .small {
                .subtitle {
                    @include lib-font-size($font-size__s);
                    padding: 5px 10px;
                    min-height: 45px;

                    .icon-arrow-right {
                        display: none;
                    }
                }
            }
        }
    }
}

a {
    &.button {
        @include lib-button-primary();
        text-decoration: none;
    }
}
