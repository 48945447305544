.formbuilder {
  .subtemplate-wrapper {
    > label {
      margin-bottom: 0;
    }
  }

  .required {
    em {
      color: $color-gray20;
    }
  }

  .button {
    background: $theme__primary__color;
    border: 2px solid $theme__primary__color;
    color: $color-white;
    font-family: $font-family__montserrat;
  }
}