.cms-no-route {
    .no-route-image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .block {
        &.ves-widget {
            .block-content {
                text-align: center;

                .no-route-title {
                    @include lib-font-size($font-size__xxxl);
                    @include lib-css(font-weight, $font-weight__bold);
                    margin-top: 20px;
                }

                .no-route-subtitle {
                    @include lib-font-size($font-size__xxl);
                    @include lib-css(font-weight, $font-weight__bold);
                    margin-top: 100px;
                    margin-bottom: 25px;
                }
            }

            .no-route-button {
                a {
                    @include lib-button-primary();
                    text-decoration: none;
                }
            }
        }
    }
}

@include max-screen($screen__xl) {
    .cms-no-route {
        .no-route-image {
            min-height: 260px;
        }
    }
}

@include min-screen($screen__xl) {
    .cms-no-route {
        .no-route-image {
            min-height: 317px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

@include max-screen($screen__l) {
    .cms-no-route {
        .no-route-image {
            min-height: 190px;
        }

        .block {
            &.ves-widget {
                .block-content {
                    .no-route-title {
                        @include lib-font-size($font-size__xxl);
                        @include lib-css(font-weight, $font-weight__bold);
                        margin-top: 20px;
                    }

                    .no-route-subtitle {
                        @include lib-font-size($font-size__xl);
                        @include lib-css(font-weight, $font-weight__bold);
                        margin-top: 50px;
                    }
                }
            }
        }
    }
}