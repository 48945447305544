.fotorama__ {
    &thumb-border {
        @extend .fotorama-print-background;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        border: 1px solid $theme__primary__color;
        background-image: linear-gradient(to bottom right, $slider__transparant-background-light, $slider__transparant-background-dark);
        backface-visibility: hidden;
        z-index: 9;

        .fotorama__nav--dots & {
            display: none;
        }

        .fotorama__wrap--css3 & {
            @extend .fotorama-transition-for-slide;
        }
    }

    &nav__frame--thumb {
        .fotorama__img {
            border: 1px solid $color-white-dark-smoke !important;
        }
    }
}
