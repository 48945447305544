.footer-usps-wrapper,
.product-list.usp-bar {
    border-bottom: 1px solid $color-gray-light8;

    .row {
        padding: 22px 0;
    }

    ul {
        > li {
            font-family: $font-family__montserrat;
            font-weight: $font-weight__semibold;

            &:before {
                @include fa-icon;
                @extend .fas;
                display: block;
                content: fa-content($fa-var-check-circle);
                color: $color-orange2;
                float: left;
                margin-right: 17px;
                top: -2px;
                position: relative;
                font-size: $font-size__xxl;
            }
        }
    }
}

@include max-screen($screen__m) {
    .footer-usps-wrapper {
        ul {
            > li {
                span {
                    display: inline-grid;

                    &:before {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}
