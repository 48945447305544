// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $_string - Initial string
// @param {String} $_search - Substring to replace
// @param {String} $_replace ('') - New value
// @return {String} - Updated string
@function lib-str-replace($_string, $_search, $_replace: "") {
    $index: str-index($_string, $_search);

    @if $index {
        @return str-slice($_string, 1, $index - 1) + $_replace + lib-str-replace(str-slice($_string, $index + str-length($_search)), $_search, $_replace);
    }

    @return $_string;
}

// Function to generate a svg url based on the icon and a empty fill='' property
// the function replaces the empty fill='' property with the given color
//
// @author Jeroen Kosse
// @param {String} $_icon - svg url string
// @param {String} $_color - hex color value
// @return {String} - Updated svg url with the correct color
@function lib-icon-svg-url($_icon, $_color) {

    @if str-index($_icon, 'fill=\'\'') {
        @return lib-str-replace(''+$_icon, 'fill=\'\'', 'fill=\'%23#{lib-str-replace(''+$_color, '#', '')}\'');
    }

    @if str-index($_icon, 'fill:%3B') {
        @return lib-str-replace(''+$_icon, 'fill:%3B', 'fill:%23#{lib-str-replace(''+$_color, '#', '')}%3B');
    }

    @if str-index($_icon, 'stroke:%3B') {
        @return lib-str-replace(''+$_icon, 'stroke:%3B', 'stroke:%23#{lib-str-replace(''+$_color, '#', '')}%3B');
    }

    @return $_icon;
}

// @author Rene Hol
// Use content and background image to load inline svg.
// @param {String} $_icon - svg url string
// @param {String} $_color - hex color value
@mixin svg-content-background($_icon, $_color) {
    content: url(lib-icon-svg-url($_icon, $_color));
    background: url(lib-icon-svg-url($_icon, $_color)) no-repeat;
    background-size: cover;
}
