.swatch-attribute {
    &-label {
        @include lib-css(font-weight, $font-weight__bold);
        position: relative;

        &.required {
            padding-right: 10px;
        }

        &[data-required="1"]:after {
            content: '*';
            color: $error__color;
            position: absolute;
            right: -11px;
            top: -2px;
            @include lib-css(font-weight, $font-weight__semibold);
            font-size: 1em;
        }
    }

    &-selected-option {
        color: $color-gray43;
        padding-left: 17px;
    }

    &-options {
        margin-top: 10px;

        .swatch-option-tooltip-layered .title {
            width: 100%;
            height: 20px;
            position: absolute;
            bottom: -5px;
            left: 0;
            color: $color-gray20;
            text-align: center;
            margin-bottom: 10px;
        }
    }

    &.size .swatch-option,
    &.manufacturer .swatch-option {
        background: $color-gray94;
        color: $color-gray61;

        &.selected,
        &.selected {
            color: $color-black;
            background: $color-white;
            border: 1px solid $color-white;
        }
    }
}

.swatch-option {
    padding: 1px 2px;
    min-width: 30px;
    max-width: 90px;
    height: 20px;
    float: left;
    margin: 0 10px 5px 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    border: 1px solid $color-gray-light7;
    overflow: hidden;
    text-overflow: ellipsis;

    &.text {
        background: $color-gray94;
        color: $color-gray40;
        font-size: 12px;
        @include lib-css(font-weight, $font-weight__semibold);
        line-height: 20px;
        padding: 0 8px;
        min-width: 22px;
        margin-right: 7px;

        &.selected {
            background-color: $color-white !important;
        }
    }

    &.selected {
        outline: 2px solid $theme__primary__color;
        border: 1px solid $color-white;
        color: $color-gray20;
    }

    &:not(.disabled):hover {
        outline: 1px solid $color-gray60;
        border: 1px solid $color-white;
        color: $color-gray20;
    }

    &.image:not(.disabled):hover,
    &.color:not(.disabled):hover {
        outline: 2px solid $color-red9;
        border: 1px solid $color-white;
    }

    &.disabled {
        cursor: default;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
                    to left top,
                    $text-swatch-option__disabled
            );
        }
    }

    &-tooltip {
        max-width: 140px;
        max-height: 100%;
        min-height: 20px;
        min-width: 20px;
        position: absolute;
        padding: 5px;
        background: $color-white;
        color: $color-gray60;
        border: 1px solid $color-gray68;
        display: none;
        z-index: 999;
        text-align: center;

        .corner,
        &-layered .corner {
            left: 40%;
            position: absolute;
            bottom: 0;
            height: 8px;

            &:after {
                content: '';
                position: relative;
                top: 1px;
                left: -15px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 7.5px 0 7.5px;
                border-color: $color-white transparent transparent transparent;
                font-size: 1px;
            }

            &:before {
                content: '';
                position: relative;
                top: 2px;
                left: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 8.5px 0 8.5px;
                border-color: $color-gray68 transparent transparent transparent;
                font-size: 1px;
            }
        }

        .image,
        &-layered .image {
            display: block;
            height: 130px;
            width: 130px;
            margin: 0 auto;
        }

        .title {
            max-width: 140px;
            min-height: 20px;
            max-height: 200px;
            color: $color-gray20;
            text-align: center;
            display: block;
            overflow: hidden;
        }

        &-layered {
            width: 140px;
            position: absolute;
            background: $color-white;
            color: $color-gray60;
            border: 1px solid $color-gray68;
            display: none;
            z-index: 999;
            left: -47px;
        }
    }

    &-link-layered {
        position: relative;
        margin: 0 !important;
        padding: 0 !important;

        &:focus > div {
            box-shadow: 0 0 3px 1px $color-blue5;
        }
    }

    &-disabled {
        outline: none !important;
        cursor: default;
        border: 0;

        &:after {
            content: '';
            position: absolute;
            width: 42px;
            height: 2px;
            background: $error__color;
            transform: rotate(-30deg);
            z-index: 995;
            left: -4px;
            top: 10px;
        }
    }

    &-loading {
        content: url(../Magento_Swatches/images/loader-2.gif);
    }
}

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch {
    &-opt {
        margin: 20px 0;
    }

    &-more {
        display: inline-block;
        margin: 2px 0;
        text-decoration: none !important;
        position: static;
        z-index: 1;
    }


    &-visual-tooltip-layered {
        height: 160px;
        top: -170px;
    }

    &-textual-tooltip-layered {
        height: 30px;
        top: -40px;
    }

    &-option-link-layered {
        &:hover {
            > .swatch-option-tooltip-layered {
                display: block;
            }
        }
    }

    // Bugfix for Add To Cart button
    &-opt-listing {
        margin-bottom: 10px;
    }

    &-input {
        left: -1000px;
        position: absolute;
        visibility: hidden;
    }
}
