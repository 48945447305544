//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white !default;
$autocomplete__border            : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border       : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

$catalog-search-grey-light          : $color-gray-light0;
$catalog-search-grey-dark           : $color-gray20;
$catalog-search-border-color        : $color-gray82;
$catalog-search-placeholder-color   : $color-gray46;

.block-search {
    float: left;
    position: relative;
    width: 100%;
    z-index: 4;
    margin: 12px 0;

    .block {
        &-title {
            display: none;
        }

        &-content {
            margin-bottom: 0;
        }
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content      : $icon-search,
            $_icon-font-size         : 35px,
            $_icon-font-line-height  : 33px,
            $_icon-font-color        : $minicart-icons-color,
            $_icon-font-color-hover  : $minicart-icons-color-hover,
            $_icon-font-color-active : $minicart-icons-color-hover,
            $_icon-font-text-hide    : true
        );
        display: inline-block;
        float: right;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .actions {
        .action {
            &.search {
                &:after {
                    @include fa-icon;
                    @extend .fas;
                    content: fa-content($fa-var-search);
                    display: inline-block;
                    width: 16px;
                    height: 20px;
                }
            }
        }
    }

    .control {
        border-top: 1px solid $border-color__base;
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
    }

    input {
        left: -300%;
        margin: 15px 0;
        position: absolute;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
                border-top: 0;
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form{
    &.search {
        &.advanced {
            .fields {
                .range {
                    .field {
                        &:first-child {
                            position: relative;

                            .control {
                                padding-right: 25px;

                                &:after {
                                    content: ' \2013 ';
                                    display: inline-block;
                                    position: absolute;
                                    right: 0;
                                    text-align: center;
                                    top: 6px;
                                    width: 25px;
                                }
                            }
                        }

                        &:last-child {
                            position: relative;

                            div {
                                &.mage-error[generated] {
                                    left: 0;
                                    position: absolute;
                                    top: 32px;
                                }
                            }
                        }

                        &.with-addon {
                            .control {
                                padding-right: 45px;
                            }
                        }
                    }
                }
            }

            .group {
                &.price {
                    .addon {
                        .addafter {
                            background: none;
                            border: 0;
                            padding-top: 6px;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }
            }
        }
    }
}

.search {
    &.summary {
        margin-bottom: $indent__s;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }
    }
}

@include max-screen($screen__s) {
    .block-search {
        margin-top: $indent__s;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {
        .label {
            @extend .abs-visually-hidden-desktop;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            margin: 0;
            padding-right: 35px;
            position: static;
            height: 36px;
            background-color: $color-white;
            border: 1px solid $theme__primary__color;
            font-style: italic;
        }

        .action {
            &.search {
                @include lib-button-reset();
                display: inline-block;
                padding: $indent__xs 0;
                position: absolute;
                right: 10px;
                top: 0;
                z-index: 1;

                &:focus {
                    &:before {
                        @include lib-css(color, $catalog-search-grey-dark);
                    }
                }
            }
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}


@include max-screen($screen__m) {
    .col-search {
        .block-search {
            .form {
                &.minisearch {
                    position: relative;
                }
            }

            .field {
                &.search {
                    .control {
                        margin: 5px 0 0 0;
                        padding: 0;
                        border-top: 0;

                        input {
                            @include lib-input-placeholder($catalog-search-placeholder-color);
                            position: static;
                            margin: 0;
                            background-color: transparent;
                        }
                    }

                    .label {
                        display: none;
                    }
                }
            }

            .actions {
                position: absolute;
                right: 0;
                top: 0;

                .action {
                    &.search {
                        display: block;
                        right: 0;
                        top: 0;
                        background: transparent;
                        border: 0;
                        box-shadow: none;
                        padding: 8px 11px 0 0;

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.catalogsearch-result-index {
    .filter-options {
        &.category-filter {
            display: none;
        }
    }
}
