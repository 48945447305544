.shop {
    &-reviews_header {
        display: block;
        padding-bottom: 30px;
    }

    &-review {
        display: inline-block;
        width: 25%;
        flex-shrink: 0;
        padding: 10px 10px 30px;

        &-container {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-wrap(wrap);
        }

        .review {
            &-date {
                font-size: 12px;
            }

            &-text {
                padding: 5px 0;
            }
        }
    }

    &-reviews {
        .review-pagination {
            padding-top: 30px;
        }

        .pages {
            .items {
                white-space: initial;
            }

            .item {
                &.current {
                    a {
                        font-weight: bold;
                        color: black;
                    }
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .review-pagination {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

@include max-screen($screen__m) {
    .shop-review {
        width: 50%;
    }
 }
