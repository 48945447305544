.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block {
    &.widget {
        .product-item-info {
            width: auto;
        }

        .pager {
            padding: 0;

            .toolbar-amount {
                float: none;
                @include lib-font-size(12);
            }

            .pages-item-previous {
                padding-left: 0;
            }

            .pages-item-next {
                position: relative;
            }

            .items {
                white-space: nowrap;
            }
        }

        .block-title {
            border-bottom: 1px solid $color-gray64;
            padding-bottom: 22px;
            margin-bottom: 37px;
            margin-top: 40px;

            strong {
                @include lib-font-size($font-size__xl);
                @include lib-line-height($font-size__s);
                font-family: $font-family__montserrat;
                font-weight: $font-weight__bold;
            }
        }

        &.block-viewed-products-grid {
            .products-grid {
                .product-items {
                    margin-bottom: 50px;

                    li {
                        &.product-item {
                            .product-item-info {
                                position: relative;
                                padding-bottom: 55px;
                            }

                            &:hover {
                                .product-item-actions {
                                    width: 100%;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________
@include min-screen($screen__xxs) {
    .block {
        &.widget {
            &.block-viewed-products-grid {
                .products-grid {
                    .product-items {
                        li {
                            &.product-item {
                                .product-item-name,
                                .price-box {
                                    padding: 0 15px;
                                }
                                .product-item-actions {
                                    margin-bottom: 0;
                                }

                                .product-item-description {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 100%;
    }
}

@include max-screen($screen__s) {
    .block {
        &.widget {
            &.block-viewed-products-grid {
                .products-grid {
                    .product-items {
                        li {
                            &.product-item {
                                margin: 0 0 20px !important;
                                box-shadow: 0 0 10px 0 $color-gray-light3;
                                border: 1px solid $color-gray-light7;
                                width: 100% !important;

                                .product-item-info {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .block.widget {
        .products-grid {
            .product-item {
                width: 100%;
                padding: 0;
            }
        }
    }
}

@include min-screen($screen__m) {
    .sidebar {
        .block {
            &.widget {
                .pager {
                    .item {
                        &:not(.pages-item-next) {
                            &:not(.pages-item-previous) {
                                @extend .abs-no-display-desktop;
                            }
                        }
                    }

                    .pages-item-next {
                        padding: 0;

                        .action {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .block {
        &.widget {
            &.block-viewed-products-grid {
                .products-grid {
                    .product-items {
                        li {
                            &.product-item {
                                width: calc(100% / 2 - 30px);
                                margin: 0 15px;

                                &:nth-child(2n + 1) {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .block {
        &.widget {
            &.block-viewed-products-grid {
                .products-grid {
                    .product-items {
                        li {
                            &.product-item {
                                width: calc(100% / 4 - 30px);
                                margin: 0 15px;

                                &:nth-child(4n + 1) {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
