//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size        : 26px !default;
$checkout-step-title__font-weight      : $font-weight__bold !default;
$checkout-step-title__padding          : $indent__s !default;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }

    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }

    .opc-block-summary {
        .mark {
            background-color: transparent;
        }
    }

    .footer {
        &.newsletter,
        .footer-usps-wrapper,
        .footer-menu-wrapper,
        .footer-qualitymarks-wrapper {
            display: none;
        }
    }

    .field-tooltip .label {
        display: none;
    }
}

.checkout-help-text {
    a {
        text-decoration: underline;
    }
}

.experius_postcode_fieldset {
    order: 1;

    strong {
        display: block;
        width: 100%;
    }

    .field {
        &[name="shippingAddress.experius_postcode_postcode"] {
            float: left;
        }

        &[name="shippingAddress.experius_postcode_housenumber"] {
            width: 25%;
        }
    }
}

#shipping-new-address-form {
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-prefix-flex-wrap(wrap);

    .field {
        &.shippingAddress-city {
            order: 3;
        }

        &.street.shippingAddress-street {
            order: 2;
        }
    }

    .field-note {
        margin-bottom: 20px;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .form .fieldset .field {
        &[name="shippingAddress.street.0"] label {
            display: none;
        }
    }

    input:disabled {
        cursor: not-allowed;
        background-color: $color-white-fog;
    }
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;

        li {
            overflow: visible;
        }
    }

    .step-title {
        @extend .abs-checkout-title;
        @include lib-css(font-weight, $font-weight__bold);
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }

    .form-login {
        max-width: 100% !important;
        padding: 15px 0;
        border-bottom: 1px solid $color-gray-middle-border;

        .fieldset {
            margin: 0;
            width: 100%;

            .field {
                width: calc(40% - 15px);
                display: inline-block;
                margin: 0 15px 0 0;
            }

            .fieldset {
                width: 60%;
                display: inline-block;

                .field {
                    width: calc(60% - 15px);
                }

                .actions-toolbar {
                    width: calc(40% - 5px);
                    margin: 34px 0 0;
                    display: inline-block;

                    .primary,
                    .secondary {
                        width: 100%;
                        max-width: 100%;

                        button {
                            margin: 0;
                        }
                    }
                }
            }

            span {
                &.note {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .fieldset {
        > .field {
            width: 47.5%;
            margin: 0 2.5% 2rem 0;
            display: inline-block;
            vertical-align: top;

            .label {
                width: 100%;
                text-align: left;
                @include lib-css(font-family, $font-family__montserrat);
                @include lib-font-size($font-size__m);
            }

            .control {
                width: 100%;
            }

            &[name="shippingAddress.experius_postcode_housenumber"],
            &[name="shippingAddress.experius_postcode_housenumber_addition"] {
                max-width: 130px;
            }

            input[type="checkbox"] {
                &[name="experius_postcode_disable"] {
                    width: 20px;
                }
            }
        }

        .field[name="shippingAddress.prefix"] {
            width: 100%;
        }

        textarea,
        input,
        select {
            @include lib-css(font-family, $font-family__montserrat);
            @include lib-font-size($font-size__base);
            @include lib-input-placeholder($color-gray46);
            height: 44px;
            max-width: 100%;
            width: 100%;
            margin: 5px 0;
            padding-left: 10px;
            border: 1px solid $color-gray-middle-border;

            &.checkbox {
                margin: 0;
                height: 14px;
                margin-right: 5px;
            }
        }
    }
}


.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
            padding-left: 15px;
        }
    }
}

@include max-screen($screen__m) {
    .opc-wrapper {
        .form-login {
            .fieldset {
                .field {
                    width: 100%;
                }
            }
        }

        .fieldset {
            > .field {
                width: 100%;

                input[type="checkbox"] {
                    &[name="experius_postcode_disable"] {
                        width: 20px;
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}
